<template>
  <div class="service p-sm-4" style="height: auto">
    <h1 class="text-center fw-bolder tw-text-size20 mb-1">
      易團GO團媽訂單管理系統 服務條款及禁止和限制商品政策
    </h1>
    <div class="tw-container p-sm-4">
      <article>
        <p>
          歡迎使用<span class="fw-bolder"
            >易團Go團媽訂單管理系統（以下稱本系統）</span
          >，本系統是自動與Facebook社團、Facebook粉絲專頁、LINE等的訂單管理系統，提供用戶整合管理服務，使用本系統前請詳細閱讀以下服務條款，以利於您了解對於本系統之使用以及您與<u
            ><u>優碼興科技股份有限公司</u></u
          >間法律義務及權利，本服務條款亦為<u><u>優碼興科技股份有限公司</u></u
          >與本系統所有使用者間合作契約書之一部分，如您使用或購買本系統而與
          <u><u>優碼興科技股份有限公司</u></u
          >、及其關係企業之服務、合作第三方之資訊時，可能尚須遵守其他條款或條件。
        </p>
        <p>
          在您註冊成為易團Go團媽訂單管理系統會員時將於系統確認您是否同意本服務條款，如您於閱讀後勾選<span
            class="fw-bolder text-danger"
            >已閱讀完成並同意選項</span
          >即表示您已閱讀、充分瞭解並同意接受本服務條款之所有內容，本系統更新追加、優化之功能也適用於以下條款，您可以隨時於系統服務頁面看到最新的服務條款。 如您使用本系統的特定服務時（網站彙整、金流、物流、運送等服務），可能會依據該特定服務之性質，而須另行遵守本系統針對該特定服務所公告之服務條款，亦請您於閱讀並理解，並遵守相關規範。
        </p>
        <p>
          基於服務與品質，易團Go團媽訂單管理系統得在不事先通知的情況下，隨時保有更新和更改服務條款的權利。所以建議您定時檢查最新的服務條款或後台系統公告/最新消息查看，並注意可能對您產生的影響的更動。如您於任何修改或變更後繼續使用本服務，視為您已閱讀、了解並同意接受該等修改或變更。若您不同意遵守此現有或修改後的使用條款，請勿繼續使用本系統及立刻停止一切有關使用本系統的活動。 
          使用者若為限制行為能力人者，本系統服務條款，應經使用者之法定代理人同意，始生效力;使用者若為無行為能力人者，本系統服務條款，應由使用者之法定代理人代為之。若使用者為限制行為能力或無行為能力者並應於使用者之法定代理人閱讀、瞭解並同意本系統服務條款之所有內容後，方得使用本系統服務，本契約條款變更時亦同。
        </p>
      </article>
      <br />
      <article>
        <h5 class="fw-bolder">壹、會員帳戶相關條款 :</h5>
        <p>本系統使用者同意遵守下列事項:</p>
        <ul class="ps-3" style="list-style-type: decimal">
          <li>
            當您註冊使用本系統並啟動使用時，請填寫您本人最新、正確及完整的資料（包括但不限於真實姓名、連絡電話、有效地址、有效e-mail），和任何本系統要求相關資訊。若本系統發現您提供的資料為錯誤、不實或不完整，本系統保留隨時暫停或終止您的帳號使用權，並拒絕提供您使用本系統之相關權限。
          </li>
          <li>
            不得使用足致他人誤認或錯誤理解或違反善良風俗（包括但不限於侮辱、威脅性、誹謗、攻擊性、仇恨性、猥褻、不雅、違反公共秩序）之文字作為暱稱之一部份或全部，更不得與官方名稱「易團Go團媽訂單管理系統」及「<u>優碼興科技股份有限公司</u>」相似或重疊，本系統保留認定使用者名稱是否妥當之解釋權力，本系統將依誠信原則認定，並有權給予違反之用戶暫停使用、限期更正、停權等處分。
          </li>
          <li>
            不得未經合法授權前使用他人已於主管機關登記註冊之商標名稱作為暱稱之一部份或全部。
          </li>
          <li>
            使用者必須對於使用之帳戶下所發生的所有活動、行為負完全的責任，並須妥善保管使用之帳號即密碼。對於任何因密碼遭到非授權使用而產生的損失或損害，本系統不會承擔您未確保密碼安全性而產生的風險及損失。
          </li>
          <li>
            不能使用本系統來從事非法或未經合法授權之商業活動及侵害他人權利等行為，使用者之操作本系統時應符合使用者所在之國家政策及法令，如有違反，使用者應自行負完全責任，本系統保留舉發、檢舉、停止帳號權使用權限等措施相關權力。
          </li>
          <li>
            您必須對您在本系統帳戶下從事的所有行為和上傳內容(資料、圖片、表格、連結、商標等)負責。
          </li>
          <li>
            如您於註冊本系統帳戶時，欲透過您於其他網站之帳號、密碼登入，系統將於您輸入相關帳號密碼後，自動提供您於該網站註冊之資訊予您確認，您於確認內容正確後即視為同意以該資料向本網站進行註冊程序，惟該註冊資料仍應符合本服務條款之規範。
          </li>
          <li>
            會員帳號與安全:
            請於完成本系統的註冊程序後，時刻維護您所註冊之帳號、密碼等資訊安全，若您的帳號遭到盜用或有其他任何安全問題發生時，應立即通知本系統，使用者應理解並同意每次使用本系統完畢後應確實將帳號登出或者離線（不論使用環境是否為公用電腦），以確保資訊安全。
          </li>
        </ul>
      </article>
      <br />
      <article>
        <h5 class="fw-bolder">貳、法律義務與承諾</h5>
        <p>
          本系統使用者絕不為任何非法目的或以任何非法方式使用本系統，並承諾遵守中華民國相關法規及網路運用慣例。您若係中華民國以外之使用者，亦應遵守所屬國家或地域之法令。您同意並保證不得使用本系統從事任何侵害他人權益或違法行為，您並承諾遵守本系統下列禁止使用規則：
        </p>
        <ul class="ps-3" style="list-style-type: decimal">
          <li>
            使用者不得以任何方式於本系統上傳、張貼、公布、散布（包括其他未列舉之傳播方式）誹謗、傷害性、侮辱性、具威脅性、涉及隱私、攻擊性、不雅、猥褻、不實、引起他人反感、違反公共秩序、違反善良風俗、違反法令或者含歧視特定人物或族群之文字、圖片、音訊（包括任何方式呈現之檔案或資訊載體）。
          </li>
          <li>使用者不得違反基於法律或契約所生之保密義務。</li>
          <li>
            不得未經他人授權而使用他人名義使用本系統，或者以虛報方式說明與第三任之權利關係。
          </li>
          <li>
            不得利用本系統侵害他人權利（包括但不限於名譽、隱私權、營業秘密、商標權、著作權、專利權、其他智慧財產權及其他權利），且應遵守中華民國法律及使用者所在地之法律規範。
          </li>
          <li>
            不得上傳、張貼、公布、散布及任何方式傳輸含有電腦病毒、木馬或任何直接或間接對本系統或者他人電腦之軟、硬體產生中斷、破壞、回傳資料或限制功能之程式、代碼、網頁連結等電子資料。
          </li>
          <li>
            不得提供賭博、詐欺、情色等資訊，亦不得將本系統使用於詐欺用途。
          </li>
          <li>
            不得利用本系統進行不法交易或張貼虛假不實、煽動或引人犯罪之訊息。
          </li>
          <li>
            使用者不得以任何方式於本系統上傳、張貼、公布、散布（包括其他未列舉之傳播方式）未經授權之廣告郵件、垃圾郵件、連鎖信、違法之多層次傳銷訊息、詐欺資訊、及中華民國法律、政策、法規所限制之資訊。
          </li>
          <li>
            不得販賣槍枝、違法刀械、彈藥、毒品、禁藥、未經主管機關許可網路販賣之藥品、盜版軟體、其他經主管機關公告查禁物品或其他違禁物。
          </li>
          <li>
            不得試圖解碼、反向工程、拆解等方式侵入本系統
            (或其他相關服務之任何部分)，或試圖破解本系統或其他用戶傳輸、處理或儲存之資料所採取的任何加密技術或安全措施。
          </li>
          <li>不得操控任何商品價格或干擾其他使用者使用本系統之服務。</li>
          <li>
            不得故意干擾或中斷本系統或伺服器之網路連線運作，或不遵守連結至本系統之相關需求、程序、政策或規則。
          </li>
          <li>
            不得惡意攻擊或侵害本系統或第三者之智慧財產權、財產權、與其它法律上權利。
          </li>
          <li>不得影響本系統功能之公平性、流暢性或完整性。</li>
          <li>
            不得以任何形式提供恐怖行動、非法組織、非法活動任何實質支持或資源。
          </li>
          <li>
            不得違反<u>優碼興科技股份有限公司</u>所制定之禁止和限制商品政策時（包括但不限於違反智慧財產權、商品本身為違禁品以及商品描述過於誇大之情形）。
          </li>
          <li>
            不得違反中華民國個人資料保護法之規範或未經他人同意而追蹤他人或其他干擾他人或為蒐集或儲存、利用他人個人資訊等行為。
          </li>
          <li>不得為任何涉及中華民國法律，違法犯罪之行為者。</li>
          <li>
            其他經本系統查核後有正當理由足認為不適當之行為，使用者經本系統通知後應不得為之。
          </li>
        </ul>
      </article>
      <br />
      <article>
        <h5 class="fw-bolder">参 、一般條款</h5>
        <ul class="ps-3" style="list-style-type: decimal">
          <li>
            <p class="fw-bolder">系統故障、暫停、中斷：</p>
            <p>
              使用本系統過程中，因第三方網頁、基地台服務、伺服器架設、電信服務業者之網路連線、程式運作等多種不可預期之變因，仍有可能於使用過程中發生系統中斷或故障之可能，為避免造成使用者使用上不便或資料喪失、錯誤、遭人竄改、或其他經濟上損失之可能，敬請於使用本系統時，自行採取防護措施。除因不可抗力事件或因其他不可歸責於<u>優碼興科技股份有限公司</u>之事由所致者外，因本系統服務維護、更新、故障或中斷，導致使用者暫時無法使用部分或全部本系統服務功能時，<u>優碼興科技股份有限公司</u>仍會傾全力維護、維修以保障使用者權益，使用者對於系統產生中斷或故障之發生風險已有理解，並同意<u>優碼興科技股份有限公司</u>對於使用者無法使用本系統而造成之損失，不負任何損害賠償責任。
            </p>
          </li>
          <li>
            <p class="fw-bolder">一般使用措施及限制：</p>
            <p>
              Ａ.
              您同意本系統得就使用者之使用方式限制或管制，此涉及本系統設計之功能、雲端服務、伺服器架設等技術性管制措施，您同意限制或管制之範圍如1.上傳內容之最長存取期間2.用戶收發訊息的數量限制3.單一帳號可收發訊息的檔案大小限制4.不得將用戶帳號提供他人使用5.限制使用者於一段期間內登入本系統次數及登入後之使用時間6.其餘基於本系統功能、效能、硬體、伺服器所生之限制。使用本系統時您同意自行留存或備份您所上傳之重要數據或資料，若本系統將服務內之任何訊息、通訊內容刪除或未予儲存，您同意本系統毋須承擔任何責任。您知悉並同意本系統有權自行關閉一年以上未登入使用之帳號，且同意本系統有權依自身需求隨時變更使施用方式或管制，不另逕行通知。
              <br />
              Ｂ.
              您同意本系統擁有更改或終止服務的權利，並同意本系統以寄發您所註冊
              email、系統公告、官網公告及其他通知方式通知。本系統保留在任何時間、任何情況下拒絕對任何人的服務的權利，本系統亦保留自行移除任何非法、攻擊、威脅、中傷、毀謗、色情，或違反任何一方的智慧財產和服務條款帳戶的權利及義務，本系統所保留之相關權利為您所同意並瞭解。
              <br />
              Ｃ.您知悉並同意您使用於本系統的資訊內容(信用卡資訊除外)，會在未經加密的情況下傳輸於本系統以外之網路，亦同意配合技術需求而作更動。但您信用卡資訊，不論於任何情況均會在加密情況下傳遞。
              <br />
              Ｄ.
              除非得本系統或<u>優碼興科技股份有限公司</u>之書面授權，您不可以複製、出售本系統提供的任何一部分服務，亦不得將本系統之功能用於本系統容許範圍外之盈利使用。若使用者有任何形式對本系統、客戶、員工不當指控或者不當評論，您同意本系統有立即中止您的帳戶之權利。
              <br />
              Ｅ.當您上傳圖片、影片以及文字敘述到本系統時，即表示您同意任何人瀏覽，亦同意本系統檢視、陳列並儲存這些資料。
              <br />
              Ｆ. 用戶不可以使用本系統或的商標來購買搜尋引擎關鍵字廣告或網域。 
              <br />
              Ｇ.本系統不提供無限制空間讓您使用，如您上傳過多檔案數量並對其他用戶產生不利影響，本系統可要求您減少上傳或儲存於本系統之文件檔案，以確保本系統運作效能，若我們通知三次仍未改善(通知方式包括但不限於
              email、系統公告、官網公告及其他通知方式)，本系統可在不事先通知下暫時停止服務。
            </p>
          </li>
          <li>
            <p class="fw-bolder">用戶使用資料之智慧財產權義務：</p>
            <p>
              Ａ.您保證於使用本系統時，僅使用著作權人合法授權您或公眾使用、修改、重製、公開播送、改作、散布、發行、公開發表資料等相關智慧財產資料。
              <br />
              Ｂ.您使用本系統時所提供上傳、登入、輸入之任何資料（包括但不限於影片、圖片、文字）一經您以任何形式提供本系統時，即表示您保證並同意本系統及其關係企業使用、修改、重製、公開播送、改作、散布、發行、公開發表、公開傳輸、公開上映、翻譯、轉授權該等資料，不致侵害任何第三人之智慧財產權。
              <br />
              Ｃ.如您於使用本系統時違反前兩款著作財產權之使用義務，您應對<u>優碼興科技股份有限公司</u>及其關係企業或合作夥伴負損害賠償責任，您並同意此損害賠償範圍除中華民國民法規範之責任外，亦包含賠償<u>優碼興科技股份有限公司</u>及其關係企業或合作夥伴因您違反義務所產生之一切費用（包括但不限於訴訟費用、律師費、裁判費、規費、因此支出之賠償金、因此支出之維護費用）。
            </p>
          </li>
          <li>
            <p class="fw-bolder">責任範圍：</p>
            <p>
              Ａ.本系統如有刊登廣告或銷售資訊（包括但不限於文字與圖片說明、展示銷售樣品）均由各廣告商、提供產品與服務之供應商所設計及投放、刊登，您於使用本系統中所見廣告之正確性及可信度，應以理性自行判斷內容之真偽、實用性、商業性，再自行決定是否向投放廣告之供應商、廠商、店家等交易或者聯繫，就投放廣告內容之契約關係僅存於您與投放廣告之供應商、廠商、店家之間，本系統僅接受委託刊登，對於上述廣告內容不負直接或間接擔保責任。
              <br />
              Ｂ.本系統使用中，廠商或個人均可能透過本系統或經由本系統連結至其他網站提供商品買賣、服務或其他交易行為。您若因此與該等廠商或個人進行交易，各該買賣或其他合約關係均僅存在於您與各該廠商或個人之間，而與<u>優碼興科技股份有限公司</u>無涉。您應本於理性交易之判斷，先行理解或磋商其商品、服務或其他交易標的之品質、內容、履行與瑕疵擔保責任、或廠商之商譽、履約能力等權利義務，再決定是否與各該廠商或個人成立契約。如您與碼興科技股份有限公司以外之廠商或個人成立前述之買賣、服務或其他交易行為所產生之爭議，應自行向各該廠商或個人尋求救濟或者協商。本系統絕不介入您與廠商或個人間之任何買賣、服務或其他交易行為，同時對於您所獲得的商品、服務或其他交易標的亦不負直接或間接的擔保責任。
              <br />
              Ｃ.若您刊登之商品或者您於本系統帳戶之使用方式因違反本服務條款、禁止和限制商品政策而遭刪除，對於該商品所購買的付費廣告加值服務，<u>優碼興科技股份有限公司</u>不負任何退費、補償或損害賠償責任。
              <br />
              Ｄ.若您刊登之商品或者您於本系統帳戶之使用方式因違反本服務條款、禁止和限制商品政策而遭刪除，對於該刊登商品受有侵害之第三人，<u>優碼興科技股份有限公司</u>不負任何退費、補償或連帶損害賠償責任。
            </p>
          </li>
          <li>
            <p class="fw-bolder">第三方網站之連結：</p>
            <p>
              本服務或協力廠商可能會提供連結至其他網路資源的連結。您可能因此連結至其他業者經營的網站，但不表示<u>優碼興科技股份有限公司</u>與該業者有任何合作關係，連結的網站均不受
              <u>優碼興科技股份有限公司</u>控制，且
              <u>優碼興科技股份有限公司</u>
              對於任何連結網站的內容、連結網站中包含的任何連結，或是該等網站的任何變更或更新，概不負責。本系統對任何外部連結，不擔保其合適性、正確性、有效性、品質或效能。又若您認為第三方網站內容涉及侵權或提供不實資訊，請您逕向該第三方網站之內容提供者或主管機關反映、舉發、檢舉。
            </p>
          </li>
          <li>
            <p class="fw-bolder">由第三方服務業者所提供之特定服務說明：</p>
            <p>
              如您選擇使用本系統之特定服務（如金流及物流服務等），由於本系統之特定服務係由第三方服務業者所提供，當您使用本系統之特定服務時，應遵守由第三方服務業者所制定之服務條款，並應隨時注意第三方服務業者所制定服務條款之修正與變更情形，本系統不負任何連帶責任。惟您與第三方服務業者聯繫遇有障礙時，本系統客服人員將協助您取得與第三方服務業者聯繫方式。
            </p>
          </li>
        </ul>
      </article>
      <br />
      <article>
        <h5 class="fw-bolder">肆、智慧財產權保護：</h5>
        <p>
          本系統所使用之軟體或程式、網站上所有內容（包括但不限於著作、圖片、檔案、資訊、資料、網站架構、網頁設計），均由<u>優碼興科技股份有限公司</u>、或經其他權利人依法授權而由<u>優碼興科技股份有限公司</u>擁有其智慧財產權，包括但不限於商標權、專利權、著作權、營業秘密與專有技術等。任何人不得逕自使用、修改、重製、公開播送、公開傳輸、公開演出、改作、散布、發行、公開發表、進行還原工程、解編或反向組譯。若您欲引用或轉載前述軟體、程式或網站內容等，除明確為法律規定許可者外，必須依法取得<u>優碼興科技股份有限公司</u>或其他權利人的事前書面同意。本系統為行銷宣傳本服務，就本系統相關之商品或服務名稱、圖樣等（以下稱本系統商標），依其註冊或使用之狀態，受商標法及公平交易法等保護，未經<u>優碼興科技股份有限公司</u>事前以書面同意，您同意不以任何方式使用本系統商標。
        </p>
        <p>
          在本系統建構的網路系統上，用戶上架的相關商品(包括但不限於商店名稱、公司名稱、聯繫人及聯繫
          訊息、產品描述說明、相關圖片、影片等)的資料均由賣家使用者提供，賣家依法應對其提供的任何信息承擔全部責任。
        </p>
      </article>
      <br />
      <article>
        <h5 class="fw-bolder">伍、不可抗力：</h5>
        <p>
          因天災或其他不可抗力事件，或其他不可歸責於<u>優碼興科技股份有限公司</u>之事由，致本系統加值服務或付費廣告無預警暫停、中斷或故障時，<u>優碼興科技股份有限公司</u>不負任何損害賠償責任或補償責任。
        </p>
      </article>
      <br />
      <article>
        <h5 class="fw-bolder">陸、解除或終止契約：</h5>
        <p>
          若因可歸責於您之事由而無法履行本服務條款，<u>優碼興科技股份有限公司</u>得訂定相當期間請求您修正。若經催告後仍未修正，<u>優碼興科技股份有限公司</u>得逕行解除或終止雙方契約關係，並得向您請求損害賠償及因此支出之費用（包括但不限於訴訟費用、律師費、裁判費、規費、因此支出之賠償金、因此支出之維護費用）
        </p>
      </article>
      <br />
      <article>
        <h5 class="fw-bolder">柒、違反本服務條款時本系統處置</h5>
        <p class="fw-bolder">
          使用者若違反此本系統之「服務條款」可能遭受某種程度不利益處分，包括但不限於：
        </p>
        <ul class="ps-3" style="list-style-type: decimal">
          <li>刪除刊登商品或移除不當之內容</li>
          <li>限制帳戶權限</li>
          <li>中止及終止帳戶</li>
          <li>採取法律行動</li>
        </ul>
      </article>
      <br />
      <article>
        <h5 class="fw-bolder">捌、爭議處理與管轄法院：</h5>
        <ul class="ps-3" style="list-style-type: decimal">
          <li>本契約書之成立、解釋及履行，以中華民國法律為準據法。</li>
          <li>
            您同意因本契約書而生、或與本契約書一切相關之法律行動或訴訟，以臺灣
            台中地方法院為第一審管轄法院。
          </li>
        </ul>
      </article>
      <br />
      <article>
        <h5 class="fw-bolder">玖、費用期間及收費方式</h5>
        <p>
          本系統付費版目前使用銀行ATM方式支付，初期註冊系統時我們有提供30天免費試用，
          您可以隨時停止使用服務。若未付費續用則系統時間到期後，亦會自動關閉您的系統帳號即表示您無法再登入後台，故賣家應在使用期限內自行進行資料存檔。付款受理完成後，服務就會在
          24 小時
          內開始生效。若收費金額或方式有更動，本系統會即刻在官方網站或系統後台或使用第三方通訊軟體通知。 
          本系統免費試用
          30天後的最後一次更新時間是三個月前者，本系統有權在不另行通知下，刪除帳號及檔案。另為維護系統運作之效能，系統內部最多保留
          12
          個月內的訂單及帳單資料，本系統有權在不另行通知下刪除資料，賣家應在使用期限內進行資料存檔。 自註冊當日起即表示您同意本系統的政策條款，除試用
          30
          日之外，一經付款使用恕不退費。方案無法轉移其他帳號使用（如用錯帳號購買，請於購買七日內告知客服協助修改）
        </p>
      </article>
      <br />
      <article>
        <h5 class="fw-bolder">拾、其他應注意事項：</h5>
        <ul class="ps-3" style="list-style-type: decimal">
          <li>
            除上述所列之禁止和限制商品外，在買方及/或店家所屬之管轄地內為非法或受到限制的任何其他商品，或其組成包含上述商品，或是會被用於鼓勵非法或受到限制之活動的商品，或依法規命令或經主管機關認定有健康或安全疑慮的商品，均屬於禁止和限制商品。
          </li>
          <li>
            補償/賠償:您同意補償/賠償本公司及一切相關人員，因您違反相關法令或本規範所致生之一切損害及責任。
          </li>
          <li>
            若您看到違反我們政策的刊登項目，敬請逕向我們提出報告。如發生違反政策的情形，我們會向店家傳送電子郵件和系統訊息，讓他們知道所刊登的商品已自我們的網站移除。我們也會通知購買該刊登商品的買方。您有責任確保您的電話設定允許您接收通知。
          </li>
          <li>
            若有其他問題，請透過下列電子郵件地址與我們聯絡:
            twingoservice@twingo.com.tw
          </li>
        </ul>
      </article>
      <br />
      <p class="fw-bolder">立契約書人：<u>優碼興科技股份有限公司</u></p>
      <br />
      <article>
        <h5 class="fw-bolder">禁止和限制商品政策</h5>
        <p>
          店家提供商品刊登於易團Go團媽訂單管理系統前，有責任確保該商品符合中華民國法律規定及<u>優碼興科技股份有限公司</u>所制定之條款和政策，並應經過<u>優碼興科技股份有限公司</u>允許刊登以進行銷售。為提供店家明確遵循依據，針對不允許在易團Go團媽訂單管理系統銷售的禁止和限制商品，<u>優碼興科技股份有限公司</u>提供下方例示之準則。<u>優碼興科技股份有限公司</u>將不定期在必要時更新此準則，敬請定期造訪此頁面以瞭解相關更新。
        </p>
      </article>
      <article>
        <h5 class="fw-bolder">一、 若違反禁止和限制商品政策：</h5>
        <p>
          使用者若違反此「禁止和限制商品政策」可能遭受某種程度不利益處分，包括但不限於：
        </p>
        <ul class="ps-3" style="list-style-type: decimal">
          <li>刪除刊登商品</li>
          <li>限制帳戶權限</li>
          <li>中止及終止帳戶</li>
          <li>採取法律行動</li>
        </ul>
      </article>
      <article>
        <h5 class="fw-bolder">二、禁止和限制商品清單</h5>
        <div>
          <p class="fw-bolder">(1)禁止類：</p>
          <ul class="ps-3" style="list-style-type: decimal">
            <li>國家級寶藏和文化資產。</li>
            <li>活體動物和保育類動物產製品 (包括但不限於野生動物)。</li>
            <li>
              煙草或與煙草相關產品，包括但不限於電子香煙及其原料和相關使用工具，如霧化器。
            </li>
            <li>毒品、相關產製品及吸毒用具。</li>
            <li>主管機關公告未開放之第二級醫療器材和第三級醫療器材。</li>
            <li>
              任何含酒精成分、以容量計算超過0.5%之飲料、及任何其他可供製造或調製上述飲料之未變性酒精及其他製品。
            </li>
            <li>偽造的貨幣、紙鈔、有價證券、彩券和郵票。</li>
            <li>信用卡和金融卡。</li>
            <li>藥物、藥品 (處方藥或指示藥)、類藥性物質及與性倒錯相關物質。</li>
            <li>
              槍炮、彈藥、武器、刀械，及供節慶、娛樂及觀賞之炮竹煙火，如仿製品和電擊槍等。
            </li>
            <li>
              電信、電子監控設備及其他類似的電子設備，如有線電視解碼器、雷達掃描器、交通信號控制裝置、提及違法用途(偷拍、竊聽)竊聽裝置及電話竊聽裝置。
            </li>
            <li>軍警用物品、警械，如警棍、電氣警棍（棒）、電擊器等。</li>
            <li>與政府或警方相關的商品，如徽章、標誌或制服。</li>
            <li>政府核發之證件、證照及執照。</li>
            <li>身體器官或遺體加工製成品。</li>
            <li>開鎖、撬鎖設備。</li>
            <li>
              任何侵權的商品或行為：包括但不限於仿製品、贋品、未經授權且可能違反特定著作權、商標權、專利權、人格權或其他第三方智慧財產權的商品或商品之複製品。
            </li>
            <li>賭博性電子機台。</li>
            <li>受產製商召回的商品。</li>
            <li>猥褻、煽動或叛國的資料。</li>
            <li>
              不符合銷售及/或遞送國家適用法律的出版品、書籍、電影、影片及/或電動遊戲。
            </li>
            <li>失竊商品。</li>
            <li>標示錯誤的商品。</li>
            <li>權利車。</li>
            <li>專用垃圾袋。</li>
            <li>二手私密衣物。</li>
            <li>以圖利為目的轉售非自用的娛樂票券（如：演唱會門票轉售）。</li>
            <li>一歲以下嬰兒配方奶粉。</li>
            <li>不動產、店面頂讓、保險、生前契約。</li>
            <li>
              濫用<u>優碼興科技股份有限公司</u>名義。如：聲稱自己為<u>優碼興科技股份有限公司</u>製造商。
            </li>
            <li>涉及違法換匯交易行為。</li>
            <li>手機強波器、增波器。</li>
            <li>檳榔/檳榔果乾。</li>
            <li>齒頰式捕獸夾/大型捕獸夾。</li>
            <li>核災食品。</li>
            <li>圖片、影片含有或暗示色情、猥褻之意涵。</li>
          </ul>
        </div>
        <div>
          <p class="fw-bolder">(2)限制類：</p>
          <ul class="ps-3" style="list-style-type: decimal">
            <li>
              服務：性服務、本質違法的服務、違反服務條款的服務，或之後<u>優碼興科技股份有限公司</u>明文禁止之服務。
            </li>
            <li>
              食品：店家刊登此類商品時應遵守《健康食品管理法》及《食品安全衛生管理法》之規定。如果店家刊登商品是經衛生福利部核准之健康食品，應一併註明核准字號，如衛署健食字第ooooooo號，且得於刊登內容中描述經核准之保健功效，但不得逾越核准範圍。
            </li>
          </ul>
        </div>
      </article>
      <article>
        <h5 class="fw-bolder">
          三、為了使用者的安全，店家不得在我們的網站上刊登下列食品：
        </h5>
        <ul class="ps-3" style="list-style-type: decimal">
          <li>
            刊登含醫療聲明的食品：聲明商品用於診斷、治療、緩解、處理或預防人類及/或動物的疾病、避孕、引發麻醉，或以其他方式預防或干擾生理功能的正常運作，無論是永久或暫時性的，或是終止、減少、延遲、增加或加速該功能的運作。
          </li>
          <li>
            有毒的食品：包含禁止物質的食品、物質含量超出許可比例的食品、未在銷售時向買方充分告知該交易性質的摻雜食品；會對人體健康造成危害的其他食品。
          </li>
          <li>
            環境用藥:店家刊登此類商品時應遵守《環境用藥管理法》之規定，對於製造、加工或輸入環境用藥，應先完成查驗登記並取得環境用藥許可證；如未持有「環境用藥許可證」及「環境用藥販賣業許可執照字號」者，不得販售環境用藥。如屬於天然成分的環境用藥，則需檢具環保署核發之核准函。店家於本平台上販售環境用藥時，應一併揭露「廠商名稱」及「環境用藥許可證字號」、「環境用藥販賣業許可執照字號」或「環保署核發之核准函」。如未詳實記載者，不得在本平台上刊登販售環境用藥。
          </li>
          <li>
            化妝品/保養品:店家刊登此類商品時應遵守《化妝品衛生安全管理法》之規定。
          </li>
          <li>
            應經檢驗商品:店家刊登此類商品時應遵守《商品檢驗法》之規定，如店家刊登的商品屬於經濟部公告之應檢驗商品，則商品本體應標示合格商品檢驗標識。店家在刊登此類商品時應自行檢視商品上是否貼有合格標識，並將含有合格標識之商品圖片刊登於賣場中；如由店家自行進口或製造該類商品銷售，亦應遵守商品檢驗法之規定完成檢驗程序，並應依前述之合格標識揭露於商品說明中。
          </li>
          <li>
            電信射頻管制器材:店家刊登此類商品時應遵守《電信法》之規定，此類商品須通過無線射頻型式認證檢驗合格，且應在商品說明中提供商品的型式認證編號，或於商品圖片中揭露明確可資辨識之型式認證編號。
          </li>
          <li>
            禮券:
            店家刊登此類商品時應遵守消基會《定型化契約應記載及不得記載事項》之規定，確認禮券中有確實記載「發行人履約保證責任內容」外，也應於商品說明中揭露禮券是否明確記載「發行人履約保證責任內容」。
          </li>
          <li>
            旅遊業務/機票：店家刊登此類商品時應遵守《發展觀光條例》及《旅行業管理規則》之規定。
          </li>
          <li>
            車輛出租：店家刊登此類服務時應遵守《公路法》及《汽車運輸業管理規則》之規定。
          </li>
          <li>涉及改造風險或殺傷性槍械、槍枝零組件、配件、子彈/彈殼。</li>
          <li>
            乙類成藥：賣方刊登此類商品時應遵守《網路零售乙類成藥注意事項》之規定。
          </li>
        </ul>
      </article>
      <!-- 下一步 -->
      <div class="row mt-5" v-if="is_showNext">
        <div class="col-sm-10 col-12 p-0 mb-sm-0 mb-3">
          <div class="form-check p-0">
            <input
              class="form-check-input m-0 me-2"
              type="checkbox"
              id="Service"
              v-model="check"
            />
            <label class="form-check-label fw-bolder text-danger" for="Service">
              本人已詳細閱讀服務條款及禁止和限制商品政策
            </label>
          </div>
        </div>
        <div class="col-sm-2 col-12 p-0 text-end">
          <button class="tw-btn tw-btn-success" @click="next">下一步</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      check: false,
      is_showNext: false,
    }
  },
  created() {
    if (this.$route.query.from === 'paymentRecord' || this.$route.query.from === 'register') this.is_showNext = true
  },
  methods: {
    next() {
      if (!this.check) this.SweetAlert('other', '您尚未勾選"本人已詳細閱讀服務條款及禁止和限制商品政策"')
      else {
        if (this.$route.query.from === 'paymentRecord') this.$router.push({name: 'Privacy', query: this.$route.query})
        else this.$router.push(`/ETwingo/privacy?from=register`)
      }
    },
  },
}
</script>